import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import { Box, Divider, Paper, Typography, styled } from '@mui/material';
import {
    type HadithBookTitle,
    sortBookTitlesByAuthenticity,
} from '@utils/sortBooks';
import { removeTashkeel } from '@utils/tashkeel';

import { ResultItemCommentary, ResultItemCommentaryRow } from './styles';
import {
    NarratorBioSchema,
    NarratorCommentary,
} from 'shared/interfaces/hadith';

const NarratorsBioItemContainer = styled(Paper)(() => ({
    borderRadius: '10px',
}));

const NarratorsBioResultItem = ({ item }: { item: NarratorBioSchema }) => {
    const { t } = useTranslation('narratorBio');
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();
    return (
        <NarratorsBioItemContainer
            key={item._id}
            elevation={0}
            variant="outlined"
            sx={{
                margin: { xs: '10px 0', md: '10px 0' },
                width: '100%',
                padding: 2,
            }}
        >
            {Object.entries(item).map(([key, value]) => {
                if (
                    value &&
                    key !== 'highlight' &&
                    key !== '_id' &&
                    key !== '_index' &&
                    key !== '_click_id' &&
                    key !== '_score'
                ) {
                    return (
                        <Box key={key}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        key === 'commentary' ? 'column' : 'row',
                                    gap: '10px',
                                }}
                            >
                                <Typography fontSize={fontSize.title}>
                                    {t(key as keyof typeof item)}:
                                </Typography>
                                {key !== 'book_titles' &&
                                    key !== 'commentary' &&
                                    key !== 'top_students' &&
                                    key !== 'top_scholars' && (
                                        <Typography
                                            fontSize={fontSize.subtitle}
                                            sx={{ color: '#121212' }}
                                        >
                                            {value as string | number}
                                        </Typography>
                                    )}
                                {(key == 'book_titles' ||
                                    key == 'top_scholars' ||
                                    key == 'top_students') && (
                                    <ResultItemCommentary>
                                        {sortBookTitlesByAuthenticity(
                                            value as HadithBookTitle[],
                                        ).map((val, index) => {
                                            return (
                                                <ResultItemCommentaryRow
                                                    key={index}
                                                >
                                                    <Typography
                                                        fontSize={
                                                            fontSize.subtitle
                                                        }
                                                    >
                                                        {val}
                                                    </Typography>
                                                </ResultItemCommentaryRow>
                                            );
                                        })}
                                    </ResultItemCommentary>
                                )}

                                {key == 'commentary' && (
                                    <ResultItemCommentary>
                                        {(value as NarratorCommentary[]).map(
                                            (val, index) => {
                                                return (
                                                    <ResultItemCommentaryRow
                                                        key={index}
                                                        sx={{
                                                            marginInlineStart:
                                                                '30px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                            }}
                                                            fontSize={
                                                                fontSize.subtitle
                                                            }
                                                        >
                                                            {val.commenter}
                                                        </Typography>
                                                        <Typography
                                                            fontSize={
                                                                fontSize.body
                                                            }
                                                            sx={{
                                                                marginInlineStart:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {val.comments.map(
                                                                (
                                                                    line,
                                                                    index,
                                                                ) => (
                                                                    <Box
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {tashkeelEnabled
                                                                            ? line
                                                                            : removeTashkeel(
                                                                                  line,
                                                                              )}
                                                                        <br />
                                                                    </Box>
                                                                ),
                                                            )}
                                                        </Typography>
                                                    </ResultItemCommentaryRow>
                                                );
                                            },
                                        )}
                                    </ResultItemCommentary>
                                )}
                            </Box>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    );
                }
            })}
        </NarratorsBioItemContainer>
    );
};

export default NarratorsBioResultItem;
